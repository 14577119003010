.top-bar-gradient {
  height: 4px;
  background: linear-gradient(
    318deg,
    rgba(176, 0, 171, 1) 0%,
    rgba(143, 140, 255, 1) 100%
  );
}

.opacity-10 {
  opacity: 0.1;
}

.nato-sans {
  font-family: 'Noto Sans JP', sans-serif;
}

.loadingWrap { display:fixed; top: 0; left:0; width: 100%; height: 100vh; }
.loadingWrap img {margin-left: auto; margin-right:auto; width: 100px; height: 100px;  }